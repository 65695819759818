@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');

html,
body {
  margin: 0;
  padding: 0;
}

a:hover {
  text-decoration: none;
}

video {
  max-width: 100%
}

strong {
  font-weight: 700;
  color: #333;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: 'Raleway', sans-serif;
  background-color: #FAFAFA;
  /*background: url("pattern.png");*/
  background-size: cover;
}

* {
  box-sizing: border-box
}

#app>div {
  flex: 1;
  display: flex;
  flex-direction: row;
}


header {
  flex:0 0 100px;
  display: flex;
  flex-direction: row;
  background-color: #6DAB1F;
  /*background: url("pattern-mini.png");*/
  background: rgba(98, 195, 68, 1);
  background: linear-gradient(135deg, rgba(98, 195, 68, 1) 0%, rgba(178, 232, 123, 1) 54%, rgba(178, 232, 123, 1) 100%);
  /*      background-size:cover;*/
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  margin-bottom: 8px;
  padding-top: 40px;
}

body.beta header {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#a90329+0,8f0222+44,6d0019+100;Brown+Red+3D */
background: #a90329; /* Old browsers */
background: -moz-linear-gradient(top, #a90329 0%, #8f0222 44%, #6d0019 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, #a90329 0%,#8f0222 44%,#6d0019 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, #a90329 0%,#8f0222 44%,#6d0019 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
body.localhost header {/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#499bea+0,207ce5+100;Blue+3d+%237 */
  background: #499bea; /* Old browsers */
  background: -moz-linear-gradient(top, #499bea 0%, #207ce5 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #499bea 0%,#207ce5 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #499bea 0%,#207ce5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

header>.brand {
  color: white;
  width: 60px;
  background: url('logo.jpg');
  background-size: 50px;
  background-position: center;
  background-repeat: no-repeat
}

header>.path {
  color: white;
  padding: 10px;
  flex: 4;
  font-size: 120%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}

header>.path .active {
  font-size: 150%
}

header>.path a {
  color: white;
}

header>.path a:before {
  content: '>';
  padding: 0 0.5em;
}

header>.path a.home:before {
  content: '';
  padding: 0;
}

header>.tools {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 0;
}

header button {
  max-width: 60px;
  flex: 1;
  font-size: 30px;
  border: none;
  background-color: transparent;
  color: #FAFAFA;
  opacity: 0.8;
}

header>.tools a {
  max-width: 60px;
  flex: 1;
  font-size: 30px;
  border: none;
  background-color: transparent;
  color: #FAFAFA;
  opacity: 0.8;
  display:flex;
  flex-direction: column;
  justify-content: center;
  margin-right:0.25em;
}

header>.tools button:hover,header>.tools a:hover {
  color: white;
  opacity: 1;
}

header>.tools {
  width: 80px;
}

header .search {
  position: relative;
  margin: 10px
}

header .search .fa {
  position: absolute;
  right: 15px;
  line-height: 30px;
  color: #FAFAFA;
}

header .search input {
  margin: 0;
  line-height: 35px;
  height: 35px;
  font-size: 25px;
  display: block;
  border: none;
  width: 200px;
  background-color: transparent;
  color: #FAFAFA;
  border-bottom: 1px solid #FAFAFA;
}

header .search input::placeholder {
  color: #FAFAFA;
}

body.showNav #buttonToggleLeftNav {
  color: #6DAB1F;
  background-color: #FAFAFA;
}

footer {
  height: 40px;
}

#app>#notifications-center {
  display: block;
  position: fixed;
  min-width: 250px;
  right: 1em;
  bottom: 0;
  top: 1em;
  z-index: 15;
  pointer-events: none;
}

#notifications-center>* {
  pointer-events: all;
  width: 100%;
  height: auto;
  margin: 1em 0;
  padding: 1em;
}

#notifications-center>.deleting {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.sites-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around
}

.site-preview {
  flex: 0 0 95%;
  margin: 0.5em 2.5%;
  height: 180px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
  display: flex;
  flex-direction: column;
}

.site-preview h3 {
  margin: 0 0 0.5em 0;
  text-overflow: clip ellipsis;
  height: 24px;
  overflow: hidden;
  padding: 0.25em 0.5em;
  font-size: 16px;

  background: rgba(128, 106, 100, 1);
  background: linear-gradient(135deg, rgba(128, 106, 100, 1) 0%, rgba(182, 160, 154, 1) 54%, rgba(182, 160, 154, 1) 100%);
  color: white;
}

.site-preview>div {
  flex: 1;
}

.site-preview h4 {
  background-color: rgba(0, 0, 0, 0.6);
  margin: 0;
  text-overflow: clip ellipsis;
  height: 18px;
  overflow: hidden;
  color: white;
  padding: 0.25em 0.5em;
  font-size: 12px;
}

.experience-preview-container {

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2em;
}

.experience-preview {
  flex: 0 0 95%;
  margin: 0.5em 2.5%;
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
}

.experience-preview img {
  width: 100%;
  aspect-ratio: 2.5;
  object-fit: cover;
}

.experience-preview h3 {
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  background: rgba(128, 106, 100, 1);
  padding: 0 0.5em;
  background: linear-gradient(135deg, rgba(128, 106, 100, 1) 0%, rgba(182, 160, 154, 1) 54%, rgba(182, 160, 154, 1) 100%);
  color: white;
  font-size: 16px;
}

.applications-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around
}

.application-preview {
  flex: 0 0 95%;
  margin: 0.5em 2.5%;
  height: 180px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16), 0 1px 2px rgba(0, 0, 0, 0.23);
  display: flex;
  flex-direction: column;
}

.application-preview h3 {
  margin: 0 0 0.5em 0;
  padding: 0.25em 0.5em;
  font-size: 16px;

  background: rgba(128, 106, 100, 1);
  background: linear-gradient(135deg, rgba(128, 106, 100, 1) 0%, rgba(182, 160, 154, 1) 54%, rgba(182, 160, 154, 1) 100%);
  color: white;
}

.application-preview>div {
  flex: 1;
}

.application-preview h4 {
  background-color: rgba(0, 0, 0, 0.6);
  margin: 0;
  text-overflow: clip ellipsis;
  height: 18px;
  overflow: hidden;
  color: white;
  padding: 0.25em 0.5em;
  font-size: 12px;
}

aside {
  flex: 1;
}



#content {
  flex: 3;
  overflow-y: auto;
  background-color: #FAFAFA;
  padding: 0.5em 1em;;
  overflow-x: hidden;
} 

#app-preview {
  display: none;
}

h1,
h2 {
  margin: 0
}

p.bg-danger, p.bg-warning,p.bg-success,p.bg-info{
  padding:1em;
  border-radius: 4px;
}
.row-flex {
  display: flex;
  flex-direction: "row"
}



/*modal*/

/*animation*/

.slideInFromBottom-enter {
  transform: translateY(100%);
}

.slideInFromBottom-enter.slideInFromBottom-enter-active {
  transform: translate(0%);
  transition: transform 500ms ease-in-out;
}

.slideInFromBottom-leave {
  transform: translateY(0);
}

.slideInFromBottom-leave.slideInFromBottom-leave-active {
  transform: translateY(100%);
  transition: transform 500ms ease-in-out;
  ;
}

.opacity-enter {
  opacity: 0;
}

.opacity-enter.opacity-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

.opacity-leave {
  opacity: 1;
}

.opacity-leave.opacity-leave-active {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
  ;
}


#content.statistics h3 {
  text-align: center
}
.btn-default {
  border-width: 0;
}
.btn-warning{
  color:#eea236;
  background-color:white;
}
.btn-danger{
  color:#d43f3a;
  background-color:white;
}
.btn-info{
  color:#5bc0de;
  background-color:white;
}
.btn-group > .btn {
  border-width:1px;
}

@media screen and (min-width:640px) {

  .site-preview {
    flex: 0 0 300px;
    margin: 0.5em;
  }

  .experience-preview {
    flex: 0 0 300px;
    margin: 0.5em;
  }
  .application-preview {
    flex: 0 0 170px;
    margin: 0.5em;
  }

  nav {
    display: block;
    width: 250px;
    position: relative;
    left: 0;
    top: auto;
    bottom: auto;
    background-color: #FAFAFA;
  }

  header {
    flex: 0 0 60px;
    padding-top: 0;
  }

  header>.path {
    padding: 20px;
    display: block;
    position: relative;
    white-space: nowrap;
    overflow-x: hidden;
  }

  #buttonToggleLeftNav {
    display: none
  }
}
